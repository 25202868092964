import * as yup from 'yup';
import { PaginationInfo } from './pagination';

export enum CurveManagerDialogs {
	CreateCurve = 'CreateCurve',
	EditCurve = 'EditCurve',
}

export enum CurveType {
	PRESSURE_CURVE = 'pump_curve',
	HEAD_CURVE = 'head_curve',
	TANK_CURVE = 'tank_curve',
	POWER_CURVE = 'power_curve',
	EFFICIENCY_CURVE = 'efficiency_curve',
	SYSTEM_CURVE = 'system_curve',
}

export interface CurveManagerDeleteModal {
	open: boolean;
	curveId: string;
}

export interface CurveManagerOverwriteModal {
	open: boolean;
	curveType?: CurveType;
	duplicatedAssets?: string[] | null | undefined;
	validatedAssets: string[];
	initialized: boolean;
	error?: string;
}

export interface CurveManagerStoreState {
	dialog: CurveManagerDialogs | undefined;
	curveById: CurveById;
	bulkCurveActionResult: CurveOperationState;
	paginatedCurveList: PaginatedCurves;
	deleteModal: CurveManagerDeleteModal;
	overwriteModal: CurveManagerOverwriteModal;
}

export interface CurveById {
	curve?: Curve;
	initialized: boolean;
	error?: string;
}
export interface Curve extends Asset {
	depthArray: number[];
	volumeArray: number[];
	xSeries?: CurveSeries;
	ySeries?: CurveSeries;
	oldAssetType?: string;
}
export interface PaginatedCurves {
	assets: Curve[];
	pagination: PaginationInfo;
	initialized: boolean;
	tableState: TableState;
}

export interface TableState {
	limitIndex: number;
	searchQuery: string;
	page: number;
}

export interface Asset {
	_id: string;
	name: string;
	assetType: string;
	curveId: string;
	assetId: string;
}

export interface GetCurvesParams {
	limit?: number;
	offset?: number;
	assetTypes?: string;
	searchQuery?: string;
	sort?: {
		sortDirection: string;
		sortField: string;
	};
}

export interface CurveManagerForm {
	_id?: string;
	curveName: string;
	xSeries?: CurveSeries;
	ySeries?: CurveSeries;
	curveType: CurveType;
	curveData: Curve[];
}

export const curveDataSchema = yup.object().shape({
	curveName: yup.string().trim().required('Curve name is required'),
	curveType: yup.string().oneOf(Object.values(CurveType)),
	xSeries: yup.object().shape({
		type: yup.string(),
		unit: yup.string(),
	}),
	ySeries: yup.object().shape({
		type: yup.string(),
		unit: yup.string(),
	}),
	curveData: yup.array().of(
		yup.object().shape({
			depthArray: yup.array().of(yup.number()),
			volumeArray: yup.array().of(yup.number()),
		}),
	),
});

export enum CurveOperationType {
	CREATE = 'create',
	UPDATE = 'update',
	DELETE = 'delete',
}

export interface CurveSeries {
	type: string;
	unit: string;
}

export interface CurveOperationBody {
	_id?: string;
	name?: string;
	depthArray?: number[];
	volumeArray?: number[];
	xSeries?: CurveSeries;
	ySeries?: CurveSeries;
	status?: number;
}

export interface CurveOperationData {
	type?: CurveType;
	body?: CurveOperationBody;
	status?: number;
}

export interface CurveOperation {
	operation: CurveOperationType;
	data?: CurveOperationData;
}

export type CurveBulkOperationState =
	| 'not_initialized'
	| 'processing'
	| 'success'
	| 'error';
export interface CurveOperationState {
	status: CurveBulkOperationState;
	response?: CurveOperationResponse;
}
export interface CurveOperationResponse {
	items: CurveOperation[];
	errors: boolean;
}
export interface BulkActionPayload {
	form: CurveManagerForm;
	operation: CurveOperationType;
	isCurveUnitsEnabled: boolean;
	selectedCurve?: Curve;
}

export const CURVE_TYPES =
	'pump_curve,head_curve,tank_curve,power_curve,efficiency_curve,system_curve';

export type CurveParsedFile = Record<string, string | number>[];

export type FileWithPromise = {
	file: File;
	promise: PromiseSettledResult<CurveParsedFile>;
};

export type FileWithValue = {
	file: File;
	value: CurveParsedFile;
};

export type CheckDuplicateCurveByIdPayload = {
	form: CurveManagerForm;
	validatedAssets: string[];
};
