import { getTenantId } from '../storages';
import {
	DownloadResponse,
	FetchParams,
	SignedS3Response,
	SignedUrl,
} from '../types/files';

export default class S3Utils {
	constructor() {}
	public async uploadToS3(
		url: string,
		files: File[],
		params: { [key: string]: string },
		token: string,
		injectTenantId = true,
	): Promise<SignedS3Response[]> {
		const promises: Promise<void>[] = [];
		const signedResponses: SignedS3Response[] = [];

		const fileNamesAndTypes = files.map(file => ({
			name: file.name,
			type: file.type,
		}));
		const signedUrls = await this.get<SignedUrl[]>(
			url,
			{
				...params,
				files: encodeURIComponent(JSON.stringify(fileNamesAndTypes)),
			},
			token,
			injectTenantId,
		);

		signedUrls?.map(signedUrl => {
			const file = files.find(f => f.name === signedUrl.file);

			if (file) {
				promises.unshift(this.put(signedUrl.url, file));
				signedResponses.unshift({
					name: signedUrl.file,
					dateCreated: signedUrl.dateCreated,
				});
			}
		});

		await Promise.all(promises);

		return signedResponses;
	}

	constructQueryString(
		params: { [key: string]: string | number | boolean } = {},
	): string {
		const requestParams = {
			...params,
		};

		return (
			'?' +
			Object.entries(requestParams)
				.map(([key, value]) => `${key}=${value}`)
				.join('&')
		);
	}
	public async downloadFromS3(
		url: string,
		params: { [key: string]: string | number | boolean },
		token?: string,
	): Promise<DownloadResponse | undefined> {
		try {
			const response = await this.get<DownloadResponse>(
				`${url}`,
				params,
				token,
			);

			return response;
		} catch (err) {
			console.log('error: ', err);

			return;
		}
	}

	async get<T>(
		url: string,
		params: { [key: string]: string | number | boolean } = {},
		token?: string,
		injectTenantId = true,
	): Promise<T | undefined> {
		const requestParams = {
			...params,
		};
		const queryString = this.constructQueryString(requestParams);
		const headers = this.createFetchHeaders(token, injectTenantId);
		try {
			const response = await fetch(
				`${url}${queryString}`,
				token
					? {
							headers,
					  }
					: undefined,
			);
			if (!response.ok) {
				throw new Error('The api call did not succeed');
			}
			return await response.json();
		} catch (e) {
			console.log('error: ', e);
			return;
		}
	}

	async put(url: string, file: File): Promise<void> {
		try {
			await fetch(url, {
				method: 'PUT',
				body: file,
			});
		} catch (e) {
			return;
		}
	}

	async delete(
		url: string,
		params: { [key: string]: string | number | boolean },
		token?: string,
		injectTenantId = true,
	): Promise<void> {
		const response = await this.sendFetch<void>({
			url,
			params,
			token,
			method: 'DELETE',
			injectTenantId,
		});

		return response;
	}

	async sendFetch<T>(fetchParams: FetchParams): Promise<T | undefined> {
		const {
			url,
			method,
			token,
			body,
			params,
			injectTenantId = true,
		} = fetchParams;
		const requestParams = {
			...params,
		};
		const queryString = this.constructQueryString(requestParams);
		const authHeaders = this.createFetchHeaders(token, injectTenantId);

		const reqBody = body;
		try {
			const response = await fetch(`${url}${queryString}`, {
				method: method ?? 'GET',
				headers: authHeaders,
				body: reqBody || undefined,
			});
			if (!response.ok) {
				throw new Error('The api call did not succeed');
			}
			return await response.json();
		} catch (e) {
			console.log('error: ', e);
			return;
		}
	}

	private createFetchHeaders(
		token?: string,
		injectTenantId = true,
	): HeadersInit | undefined {
		const headers: { 'x-tenant-id'?: string; Authorization?: string } = {};
		const tenantId = getTenantId();
		if (token) {
			headers.Authorization = `Bearer ${token}`;
		}
		if (tenantId && injectTenantId) {
			headers['x-tenant-id'] = tenantId;
		}

		return headers;
	}
}
