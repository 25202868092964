import { closeDialog } from '../../../actions/curveManager.actions';
import CurveManagerDialogForm from '../CurveManagerDialogForm';
import { StylovyzeComposableDialog } from '../../../components';
import { useSelectCurveManager } from '../../../selectors';
import { useGlobalization } from '../../../contexts';
import {
	CurveManagerDialogs,
	CurveManagerForm,
} from '../../../types/curveManager.types';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DrawerFooter from '../Drawer/DrawerFooter';
import { DropzoneTableContainer } from './CreateOrEditCurveDialog.styles';
import { CurveDataDisplay } from '../CurveDataDisplay';

const CreateOrEditCurveDialog = () => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { curveById, dialog } = useSelectCurveManager();

	const formikProps = useFormikContext<CurveManagerForm>();
	const [dialogTitle, setDialogTitle] = useState<string>('');

	const handleFormCancel = () => {
		dispatch(closeDialog());
		formikProps.resetForm();
	};

	useEffect(() => {
		if (!dialog) {
			formikProps.resetForm();
		} else if (dialog === CurveManagerDialogs.EditCurve) {
			formikProps.setFieldValue('curveName', curveById.curve?.name);
			formikProps.setFieldValue('curveType', curveById.curve?.assetType);
			formikProps.setFieldValue('xSeries', curveById.curve?.xSeries);
			formikProps.setFieldValue('ySeries', curveById.curve?.ySeries);
			const curveData = {
				depthArray: curveById.curve?.depthArray,
				volumeArray: curveById.curve?.volumeArray,
			};
			formikProps.setFieldValue('curveData', [curveData]);
			setDialogTitle(t('Edit Curve'));
		} else {
			setDialogTitle(t('Create New Curves'));
			if (curveById.curve?.assetType) {
				formikProps.setFieldValue(
					'curveType',
					curveById.curve.assetType,
				);
			}
		}
	}, [dialog]);

	const handleSubmit = async (): Promise<void> => {
		await formikProps.submitForm();
	};

	const hasDepthArrayData = useMemo((): boolean => {
		const curveData = formikProps.values.curveData;
		if (curveData.length === 0) {
			return false;
		}
		return curveData.every(curve => {
			return curve?.depthArray?.length > 0;
		});
	}, [formikProps?.values?.curveData]);

	const footButtonText = useMemo(() => {
		if (dialog === CurveManagerDialogs.EditCurve) {
			return t('Update');
		}
		return t('Create');
	}, [dialog, t]);

	const [filesLoading, setFilesLoading] = useState<boolean>(false);

	return (
		<StylovyzeComposableDialog
			title={dialogTitle}
			open={!!dialog}
			onCancel={() => void 0}
			footer={
				<DrawerFooter
					onCancelClick={handleFormCancel}
					cancelButtonDisabled={formikProps.isSubmitting}
					action={{
						title: footButtonText,
						onClick: () => void handleSubmit(),
						enabled:
							hasDepthArrayData &&
							formikProps.values.curveName?.length > 0 &&
							!formikProps.isSubmitting &&
							!filesLoading,
					}}
				/>
			}>
			<CurveManagerDialogForm formikProps={formikProps} />
			<DropzoneTableContainer>
				<CurveDataDisplay
					hasDepthArrayData={hasDepthArrayData}
					setFilesLoading={setFilesLoading}
					filesLoading={filesLoading}
				/>
			</DropzoneTableContainer>
		</StylovyzeComposableDialog>
	);
};

export default CreateOrEditCurveDialog;
