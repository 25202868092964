import { FeatureItem } from './types';
import { FieldType } from '@Types/map.types';
import { SpatialProperty } from '@Types/risk.types';

export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined;

export const notFalsy = <T>(x: T | false): x is T => x !== false;

export const getAssetIdAndType = (
	id: string,
): { assetType: string; assetId: string } => {
	const [assetType, assetId] = id.split('#');
	return { assetType, assetId };
};

export const getAssetType = (item: FeatureItem): string => {
	return item.assetType ?? getAssetIdAndType(item.id).assetType;
};

const hasDisplayValue = (
	value: SpatialProperty,
): value is SpatialProperty & { displayValue: string } =>
	typeof value === 'object' && value !== null && 'displayValue' in value;

export const camelAndSnakeCaseToWords = (s: string): string => {
	const result = s
		.replace(/([A-Z])/g, ' $1')
		.replace(/_([a-z])/g, (_, c) => ` ${c.toUpperCase()}`);
	return result.charAt(0).toUpperCase() + result.slice(1);
};

export const extractValue = (value: SpatialProperty): string | undefined => {
	if (typeof value === 'object' && value) {
		if (value?.type === 'date') return value?.value;
		if (hasDisplayValue(value)) return value?.displayValue;
	}
	return value != null ? `${value}` : undefined;
};

// Generate the map layer ID from the the asset and system type
export const getMapLayerId = (
	assetType: string,
	systemType: string,
): string => {
	// Split the systemType on the uppercase and join with a _ to ge the systemTypeName
	const systemTypeName = systemType.split(/(?=[A-Z])/).join('_');
	// Remove the ww as requried
	const layerName = assetType.replace(/^ww/, '');
	// Now make up the layerId in lower case
	return `${systemTypeName}_${layerName}`.toLowerCase();
};

export const isValidFieldType = (value: string): boolean => {
	return Object.values(FieldType).includes(value as FieldType);
};
