import { useEffect, useState } from 'react';
import { useSystemType } from './useSystemType';
import { useDispatch } from 'react-redux';
import { getSystemAssetTypes } from '@Actions/asset';
import { getSpatialLayers } from '@Actions/risk';

type SelectedLayerData = {
	dataType: string;
	layerName: string;
};
type SelectedLayerDataHookReturnType = [
	boolean,
	SelectedLayerData,
	(dataType: string) => void,
	(layerName: string) => void,
];

export function useGridViewManager(
): SelectedLayerDataHookReturnType {
	const dispatch = useDispatch();
	const [systemType, setSystemType] = useSystemType();
	const [assetType, setAssetType] = useState('');

	const isViewSelected = Boolean(systemType && assetType);
	const selectedView = { dataType: systemType, layerName: assetType };
	const setDataType = (dataType: string) => {
		setSystemType(dataType);
		setLayerName('');
	};
	const setLayerName = setAssetType;

	useEffect(() => {
		dispatch(getSystemAssetTypes());
	}, []);

	useEffect(() => {
		dispatch(getSpatialLayers());
	}, []);

	return [isViewSelected, selectedView, setDataType, setLayerName];
}
