import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Filter } from '@Utils/types';
import { timeAgo } from '@Utils/datetime';

const StyledDrawer = styled(Drawer)`
	& .MuiDialogTitle-root {
		margin: 0;
		padding-top: 3em;
	}
	& .MuiDialogTitle-root small {
		opacity: 0.5;
	}
	& .MuiDialogContent-root {
		padding: 0;
	}
	& .MuiTableContainer-root {
		overflow-x: hidden;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}
	& .MuiTable-root {
		min-width: 400px;
	}
	& .MuiTableRow-root {
		cursor: pointer;
	}
	& .MuiTableCell-root {
		max-width: 400px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	& .MuiTableCell-root small {
		opacity: 0.5;
		display: block;
	}
`;

export interface FilterDrawerProps {
	children: JSX.Element;
	assetType?: string;
	filters?: Filter[];
	opened?: boolean;
	onSelect?(filter: Filter): void | Promise<void>;
}
export const FilterDrawer = ({
	children,
	assetType,
	filters,
	opened = false,
	onSelect,
}: FilterDrawerProps): JSX.Element => {
	const [open, setOpen] = useState(opened);
	const [selectedId, setSelectedId] = useState('');
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setSelectedId('');
	};
	const handleLoad = async (filter: Filter) => {
		if (!filter._id) return;
		setSelectedId(filter._id);
		if (onSelect) await onSelect(filter);
		handleClose();
	};
	const rows = (filters || []).filter(
		f => !assetType || f.assetType === assetType,
	);
	return (
		<React.Fragment>
			{React.cloneElement(children, { onClick: handleOpen })}
			<StyledDrawer
				variant="temporary"
				anchor="right"
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}>
				<DialogTitle>
					Saved Filters {assetType && <small>({assetType})</small>}
				</DialogTitle>
				<DialogContent>
					<TableContainer>
						<Table aria-label="simple table">
							<TableBody>
								{!rows.length ? (
									<TableRow>
										<TableCell scope="row">
											No saved filters.
										</TableCell>
									</TableRow>
								) : (
									rows.map(row => (
										<TableRow
											key={row._id}
											hover
											onClick={() => handleLoad(row)}
											selected={row._id === selectedId}>
											<TableCell padding="checkbox">
												<Radio
													color="primary"
													checked={
														row._id === selectedId
													}
												/>
											</TableCell>
											<TableCell scope="row">
												{row.name}
												<small>
													Saved{' '}
													{timeAgo(row.dateCreated)}{' '}
													by{' '}
													{String(row.createdBy)
														.split('@')
														.shift()}
												</small>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions></DialogActions>
			</StyledDrawer>
		</React.Fragment>
	);
};
