import { DateTime } from 'luxon';

export const MINUTE = 60 * 1000;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export const timeAgo = (date = new Date().toISOString()): string => {
	const dt = DateTime.fromISO(date).minus(2 * MINUTE);

	// Is it within the last week?
	if (dt.toMillis() > Date.now() - 1 * WEEK) {
		// -> "15 minutes ago"
		// -> "6 hours ago"
		// -> "2 days ago"
		return dt.toRelative() ?? '';
	} else {
		// -> "11 Dec 24"
		return dt.toFormat('DD');
	}
};
