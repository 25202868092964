import { CombinedEventsOption } from '../../types';
import { InputAutocompleteProps } from '../../components/StylovyzeForm/InputAutocompleteMulti';
import InputAutocompleteMulti from '../../components/StylovyzeForm/InputAutocompleteMulti';
import { useSelectPaginatedSensorGroups } from '../../selectors';
import { useFormikContext } from 'formik';
import React from 'react';

export type SensorGroupAutocompleteProps = Omit<
	InputAutocompleteProps,
	'options'
>;

export default function SensorGroupAutocomplete(
	props: SensorGroupAutocompleteProps,
): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { setFieldTouched } = useFormikContext<any>();
	const { sensorGroups } = useSelectPaginatedSensorGroups();

	const { autocompleteProps, textFieldProps, onChange, ...restOfProps } =
		props;
	const { name } = props.fieldAttrs;

	const sensorGroupsOptions: CombinedEventsOption[] = sensorGroups.map(
		sensorGroup => ({
			key: sensorGroup._id,
			value: sensorGroup.name,
		}),
	);

	return (
		<InputAutocompleteMulti
			label={props.label || 'Sensor groups'}
			textFieldProps={{
				onBlur: () => {
					setFieldTouched(name, true);
				},
				...textFieldProps,
			}}
			onChange={e => {
				if (onChange) onChange(e);
				else setFieldTouched(name, true);
			}}
			autocompleteProps={{
				disableClearable: true,
				...autocompleteProps,
			}}
			options={sensorGroupsOptions}
			{...restOfProps}
		/>
	);
}
