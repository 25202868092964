import React from 'react';

import { Events } from '../utils/sharedWorkers.types';

export default function useRegenerateSharedWorker(
	workerRef: React.MutableRefObject<SharedWorker | null>,
	workerUrl: string,
	workerName: string,
	handleMessage: (event: {
		data: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			data: any;
			type: Events;
			extraAttributes?: Record<string, unknown>;
		};
	}) => void,
	initData: () => void,
	createSharedWorker: () => void,
) {
	/**
	 * Checks if the Shared Worker is alive by sending a 'PING' and waiting for 'PONG'.
	 * If the worker responds within TIMEOUT_MS, resolve. Otherwise, reject.
	 */
	async function checkWorkerHealth(): Promise<boolean> {
		if (!workerRef.current) {
			throw new Error('The Worker is not initialized.');
		}

		const TIMEOUT_MS = 3000;
		const port = workerRef.current.port;
		let timedOut = false;

		return new Promise((resolve, reject) => {
			function onMessage(event: MessageEvent) {
				if (event.data.type === 'PONG' && !timedOut) {
					clearTimeout(timeoutId);
					port.removeEventListener('message', onMessage);
					resolve(true);
				}
			}

			const timeoutId = setTimeout(() => {
				timedOut = true;
				port.removeEventListener('message', onMessage);
				reject(
					new Error(
						'The Worker did not respond within the expected time.',
					),
				);
			}, TIMEOUT_MS);

			port.addEventListener('message', onMessage);
			port.postMessage({ type: 'PING' });
		});
	}

	async function regenerateSharedWorker(): Promise<boolean> {
		try {
			workerRef.current?.port.close();
		} catch (err) {
			console.warn('Error closing old worker:', err);
		}

		// Create a new Shared Worker
		createSharedWorker();

		if (!workerRef.current) return false;

		// Start the port and attach your handleMessage again
		workerRef.current.port.start();
		workerRef.current.port.addEventListener('message', handleMessage);
		await initData();
		await new Promise(resolve => setTimeout(resolve, 1000));

		// Optionally re-initialize data (like initData, etc.)
		return true;
		// or any other logic to set up the worker again
	}

	return {
		regenerateSharedWorker,
		checkWorkerHealth,
	};
}
