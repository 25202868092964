import asset, { initialState as initialAsset } from './asset';
import risk, { initialState as initialRisk } from './risk';

import { StoreState } from '../types/store.types';
import { combineReducers } from 'redux';

import inspection, { initialState as initialInspection } from './inspection';
import facility, { initialState as initialFacility } from './facility';
// plop - import reducer here

export const initialState: StoreState = {
	risk: initialRisk,
	asset: initialAsset,
	inspection: initialInspection,
	facility: initialFacility,
	// plop - add default reducer state here
};

export default combineReducers({
	risk,
	asset,
	inspection,
	facility,
	// plop - add reducer key here
});
