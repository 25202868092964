import { FormControlOutline, FormControlUnderline } from './Select.styles';
import { InputLabel, MenuItem, Select } from '@mui/material';
import React, { ElementType, useEffect } from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { selectDataTypes } from '@Selectors/asset';
import { useGlobalization } from '@Translations/useGlobalization';
import { useDispatch, useSelector } from 'react-redux';
import {
	getSystemSchemaAction,
	systemSchemaSelector,
} from '@innovyze/lib_asset_schema_store';
import { camelAndSnakeCaseToWords } from '@Utils/helpers';

export interface SystemTypeSelectProps {
	systemType: string;
	onSystemTypeChange: SelectInputProps<string>['onChange'];
	topBar?: boolean;
	dataCy?: string;
}

export const SystemTypeSelect = ({
	systemType,
	onSystemTypeChange,
	topBar,
	dataCy,
}: SystemTypeSelectProps): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const systemTypes = useSelector(state => selectDataTypes(state));
	useEffect(() => {
		systemTypes.forEach(system => dispatch(getSystemSchemaAction(system)));
	}, [systemTypes, dispatch]);

	const systemSchema = useSelector(state => systemSchemaSelector(state));

	const FormControl: ElementType = topBar
		? FormControlUnderline
		: FormControlOutline;

	return (
		<FormControl>
			<InputLabel shrink={topBar}>{t('Data Type')}</InputLabel>
			<Select
				displayEmpty
				value={systemType}
				onChange={onSystemTypeChange}
				disabled={!systemTypes?.length}
				data-cy={dataCy}>
				{topBar && (
					<MenuItem disabled value="">
						{t('Select a data type')}
					</MenuItem>
				)}
				{systemTypes.map(systemType => (
					<MenuItem key={systemType} value={systemType}>
						{systemSchema[systemType]?.displayName ??
							camelAndSnakeCaseToWords(systemType)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
