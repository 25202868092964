import { Field, FieldAttributes, useField } from 'formik';
import { FORM_MODES } from '../../utils';
import { ItemWithLabel } from '../../components';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { useMode } from './contexts/StylovyzeFormContext';
import React, { useMemo, useState } from 'react';
import useGetCombinedAttributes from './hooks/useGetCombinedAttributes';
import {
	FormControl,
	ListSubheader,
	TextField,
	TextFieldProps,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete, {
	AutocompleteInputChangeReason,
	AutocompleteProps,
	AutocompleteRenderGroupParams,
	AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import useGetCombinedEvents, {
	ChangeEventWithOption,
} from './hooks/useGetCombinedEvents';
import { CombinedEventsOption } from '../../types';

export type ChangeAutocompleteEvent = (
	event: React.ChangeEvent<Record<string, unknown>>,
	value: string,
	reason: AutocompleteInputChangeReason,
) => void;

export type ChangeEventWithOptions = (
	e: React.ChangeEvent<HTMLInputElement>,
	option?: (CombinedEventsOption | string)[],
) => void;

export interface InputAutocompleteProps {
	/*
	 * fieldAttrs: formik field attributes
	 * name is required
	 */
	fieldAttrs: FieldAttributes<{}>;
	/*
	 * onChange: will be called immediately whenever the input change
	 */
	onChange?: ChangeEventWithOptions;
	/*
	 * onDebounceChange: will be called after DEBOUNCE_TIME whenever the imput change
	 */
	onDebounceChange?: ChangeEventWithOption;
	/**
	 * TODO: Parent components must ensure that options is Option[] in order to change (Option | string)[] to Option[]
	 * */
	options: (CombinedEventsOption | string)[];
	value?: string | null;
	autocompleteProps?: Omit<
		AutocompleteProps<
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			boolean | undefined,
			boolean | undefined,
			boolean | undefined
		>,
		'renderInput' | 'options' | 'value' | 'onChange' | 'onfocus'
	>;
	/*
	 * textFieldProps: text field attributes
	 */
	textFieldProps?: Omit<TextFieldProps, 'error' | 'helperText' | 'label'>;
	helperText?: string;
	networkError?: boolean;
	label?: string;
	dataCy?: string;
	error?: boolean;
	controlledInput?: [string, (inputValue: string) => void];
}

/**
 * WINDOWING
 * */

const LISTBOX_PADDING = 8;
const OuterElementContext = React.createContext({});

const useResetCache = (data: number) => {
	const ref = React.useRef<VariableSizeList>(null);

	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);

	return ref;
};

const renderRow = (props: ListChildComponentProps) => {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: LISTBOX_PADDING + Number(style.top ?? 0),
		},
	});
};

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = 'OuterElementType';

const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
	// eslint-disable-next-line react/prop-types
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (
		child: React.ReactChild | React.ReactFragment | React.ReactPortal,
	) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					height={getHeight() + 2 * LISTBOX_PADDING}
					innerElementType="ul"
					itemCount={itemCount}
					itemData={itemData}
					itemSize={index => getChildSize(itemData[index])}
					outerElementType={OuterElementType}
					overscanCount={5}
					ref={gridRef}
					width="100%">
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
	<ListSubheader key={params.key} component="div">
		{params.group}
	</ListSubheader>,
	params.children,
];

/**
 * AUTOCOMPLETE
 * */

/**
 * Returns the display value of an option if available.
 *
 * If an option is a `string`, the value represents the `key` of the original `Option` object.
 * */
const getOptionValue = (
	options: Record<string, string>,
	option: CombinedEventsOption | string,
) => {
	if (typeof option === 'string') {
		const _option = options[option];
		// If options is not found (deleted maybe ?) we return the original text defined as an option string. TODO: There's no rules defined for this scenario, yet.
		if (!_option) return option;
		return _option;
	}
	return option.value;
};

/**
 * MUI determines if an option is selected by referencial equality.
 *
 * In our case, we store the value as (string | Option) and the options passed to the autocomplete component are (string | Option) as well. So, we need to manually determine if the option is selected or not.
 *
 * Remember that if an option / value is a string, it represents the `key`property of an Option object.
 * */
const getOptionSelected = (
	opt: CombinedEventsOption | string,
	val: CombinedEventsOption | string,
) => {
	const o = typeof opt === 'string' ? opt : opt.key;
	const v = typeof val === 'string' ? val : val.key;

	return o === v;
};

const useStyles = makeStyles({
	listbox: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});

export default function InputAutocompleteMulti({
	options,
	autocompleteProps,
	fieldAttrs,
	onChange,
	onDebounceChange,
	networkError,
	label,
	dataCy,
	textFieldProps,
	helperText,
	error,
	controlledInput,
}: InputAutocompleteProps): JSX.Element {
	const mode = useMode();

	const classes = useStyles();
	const [field, , helpers] = useField<(CombinedEventsOption | string)[]>(
		fieldAttrs.name,
	);

	const inputValueState = useState<string>('');
	const [inputValue, setInputValue] = controlledInput || inputValueState;

	const combinedEvents = useGetCombinedEvents({
		fieldAttrs,
		onDebounceChange,
		networkError,
		onBlur: e => {
			helpers.setTouched(true);
			autocompleteProps?.onBlur && autocompleteProps?.onBlur(e);
			field.onBlur && field.onBlur(e);
		},
	});

	const combinedAttributes = useGetCombinedAttributes({
		networkError,
		helperText,
		error: error,
	});

	const hashedOptions = useMemo(() => {
		const optionsHash: Record<string, string> = {};

		for (let i = 0; i < options.length; i++) {
			const opt = options[i];
			optionsHash[typeof opt === 'string' ? opt : opt.key] =
				typeof opt === 'string' ? opt : opt.value;
		}

		return optionsHash;
	}, [options]);

	return (
		<FormControl
			variant="outlined"
			margin={mode === FORM_MODES.VIEW ? 'none' : 'normal'}
			fullWidth
			data-cy={dataCy}>
			{mode === FORM_MODES.VIEW ? (
				<ItemWithLabel
					label={label}
					value={field.value
						?.map(opt => getOptionValue(hashedOptions, opt))
						.join(', ')}
				/>
			) : (
				<Field
					multiple
					disableCloseOnSelect
					isOptionEqualToValue={getOptionSelected}
					{...autocompleteProps}
					classes={classes}
					value={field.value}
					limitTags={autocompleteProps?.limitTags || 4}
					name={fieldAttrs.name}
					component={Autocomplete}
					options={options}
					onBlur={combinedEvents.onBlur}
					inputValue={inputValue}
					ListboxComponent={ListboxComponent}
					renderGroup={renderGroup}
					getOptionSelected={getOptionSelected}
					getOptionLabel={(opt: CombinedEventsOption | string) => {
						return getOptionValue(hashedOptions, opt);
					}}
					onChange={(
						e: React.ChangeEvent<HTMLInputElement>,
						_options: (CombinedEventsOption | string)[],
					) => {
						setInputValue('');

						if (_options && Array.isArray(_options)) {
							const localOptions = _options.map(el => {
								return typeof el !== 'string' ? el.key : el;
							});
							helpers.setValue(localOptions);
						} else {
							helpers.setValue([]);
						}

						if (combinedEvents.onChange) {
							combinedEvents.onChange(e);
						}

						if (onChange) onChange(e, _options);
					}}
					renderInput={(params: AutocompleteRenderInputParams) => {
						return (
							<TextField
								{...params}
								{...textFieldProps}
								label={label}
								variant="outlined"
								error={combinedAttributes.error}
								helperText={combinedAttributes.helperText}
								data-cy="input-autocomplete-multi-text-field"
								inputProps={{
									...params.inputProps,
									...textFieldProps?.inputProps,
								}}
								InputProps={{
									...params.InputProps,
									...textFieldProps?.InputProps,
								}}
								onChange={e => {
									setInputValue(e.target.value);
									if (textFieldProps?.onChange) {
										textFieldProps?.onChange(e);
									}
								}}
								onBlur={e => {
									setInputValue('');
									if (textFieldProps?.onBlur) {
										textFieldProps?.onBlur(e);
									}
								}}
							/>
						);
					}}
				/>
			)}
		</FormControl>
	);
}
