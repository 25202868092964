import { StylovyzeTabs, borderBox, rem } from '@innovyze/stylovyze';
import styled, { css } from 'styled-components';

import { StyledPropsExpression } from './StyledPropsExpression';

interface PropertyPaneProps {
	open?: boolean;
	width?: number;
}

export const tabsMargin = 12;

const StyledPropertyPane = styled.div<PropertyPaneProps>`
	${borderBox};
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	min-width: ${rem(613)};
	width: min-content;
	z-index: 9;
	height: 100%;
	transform: translate(-100%, 0);
	transition: transform 0.5s;
	box-shadow: 0 ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25);
	border-radius: ${rem(4)} 0 0 ${rem(4)};
	padding: 0;
	${(props): StyledPropsExpression =>
		props.open &&
		css`
			transform: translate(0%, 0);
		`};
`;

export default StyledPropertyPane;

export const StyledStylovyzeTabs = styled(StylovyzeTabs)`
	display: flex;
	max-height: calc(100% - ${rem(70)});
	flex-direction: column;
	> .MuiTabs-root {
		min-width: 100%;
		width: max-content;
	}
	.MuiTabs-flexContainer {
		padding-left: ${rem(tabsMargin)};
		padding-right: ${rem(tabsMargin)};
		justify-content: normal;
	}
	.tabpanel {
		max-height: 100%;
		overflow-y: auto;
	}
`;

export const Actions = styled.div`
	display: flex;
	flex-direction: row-reverse;
	margin-left: auto;
	padding: ${rem(4)} ${rem(10)} ${rem(10)};
`;
