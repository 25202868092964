import * as React from 'react';

import {
	Container,
	DropzoneInfoContainerFull,
	DropzoneInfoContainerLabelMini,
	DropzoneInfoContainerMini,
	FittedLoader,
	StyledDropzoneArea,
	StyledDropzoneFileRowContainer,
} from './StylovyzeDropzone.styles';
import Dropzone, { DropEvent, DropzoneOptions } from 'react-dropzone';
import { RejectedFile, UpArrowCircle } from './icons';
import {
	deleteFile,
	downloadFromS3,
	getSignedURLs,
	pollUploadStatus,
	uploadToS3,
} from './StylovyzeDropzoneUtils';

import { AttachedFile } from '../../types/files';
import Loading from '../../components/Loading';
import { StylovyzeDropzoneRowContainer } from './StylovyzeDropzoneRowContainer';
import UploadFilePaper from './icons/UploadFilePaper';
import { amUploadBaseURL } from '../../apis/amUpload.api';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { useState } from 'react';

export interface StylovyzeDropzoneProps {
	dropzoneOptions?: DropzoneOptions;
	uploadParams?: { [key: string]: string };
	downloadApiUrl?: string;
	downloadParams?: { [key: string]: string };
	deleteApiUrl?: string;
	deleteParams?: { [key: string]: string };
	onFileDelete?: (af: AttachedFile) => void;
	onDropAccept?: (af: AttachedFile[]) => void;
	noUpload?: boolean;
	token: string;
	files?: AttachedFile[];
	mainFileText?: string;
	supportedFileText?: string;
	single?: boolean;
	onFileSelect?: () => void;
	injectTenantId?: boolean;
}

/**
 * StylovyzeDropzone
 */
export const StylovyzeDropzone = (
	props: StylovyzeDropzoneProps,
): JSX.Element => {
	const { t } = useGlobalization();
	const {
		downloadApiUrl,
		deleteApiUrl,
		token,
		injectTenantId = true,
	} = props;
	const initialAttachedFiles: AttachedFile[] = props.files || [];
	const [uploadedFiles, setUploadedFiles] =
		useState<AttachedFile[]>(initialAttachedFiles);
	const [rejectedFileNames, setRejectedFileNames] = useState<string[]>([]);
	const [uploading, setUploading] = useState<boolean>(false);
	let handleDownloadClick:
		| ((af: AttachedFile) => () => Promise<void>)
		| undefined = undefined;
	if (downloadApiUrl)
		handleDownloadClick = (af: AttachedFile) => async (): Promise<void> => {
			const { path, dateCreated } = af;
			const fileProperties = {
				...af?.props,
				...props.downloadParams,
			};
			const downloadLink = await downloadFromS3(
				downloadApiUrl ?? '',
				{
					path,
					dateCreated,
					...fileProperties,
				},
				token,
			);
			if (downloadLink?.location) {
				const href: HTMLAnchorElement = document.createElement('a');
				href.setAttribute('href', downloadLink.location);
				href.setAttribute('download', 'download');
				href.click();

				href.remove();
			}
		};

	let handleDeleteClick:
		| ((af: AttachedFile, index: number) => () => Promise<void>)
		| undefined = undefined;
	if (deleteApiUrl)
		handleDeleteClick =
			(af: AttachedFile, index: number) => async (): Promise<void> => {
				const { path, dateCreated } = af;
				const fileProperties = {
					...af?.props,
					...props.deleteParams,
				};
				await deleteFile(
					deleteApiUrl,
					{
						path,
						dateCreated,
						...fileProperties,
					},
					token,
					injectTenantId,
				);

				const newFiles = uploadedFiles;
				newFiles.splice(index, 1);
				setUploadedFiles([...newFiles]);

				if (props.onFileDelete) props.onFileDelete(af);
			};

	const onDropAccepted = async (acceptedFiles: File[], evt: DropEvent) => {
		props.onFileSelect?.();
		setUploading(true);
		setRejectedFileNames([]);
		const signedUrls = await getSignedURLs(
			amUploadBaseURL + '/v2/upload',
			acceptedFiles,
			props.uploadParams || {},
			props.token,
		);
		const uploadIds = signedUrls.map(e => e.uploadId);
		await uploadToS3(acceptedFiles, signedUrls);
		await pollUploadStatus(
			uploadIds,
			amUploadBaseURL + '/v2/upload/status',
			props.token,
			setUploading,
			[...uploadedFiles],
			[],
			setUploadedFiles,
			[],
			setRejectedFileNames,
			props.onDropAccept,
			0,
			injectTenantId,
		);
	};

	const getDragClassName = (
		isDragActive: boolean,
		isDragAccept: boolean,
		isDragReject: boolean,
	): string => {
		const classNames: string[] = [];
		if (isDragAccept) classNames.push('drag-accept');
		if (isDragActive) classNames.push('drag-active');
		if (isDragReject) classNames.push('drag-reject');
		return classNames.join(' ');
	};
	const getDZBody = (
		isDragActive: boolean,
		isDragAccept: boolean,
		isDragReject: boolean,
	) => {
		let iconFill = '#C9D6DC';
		if (isDragReject || rejectedFileNames.length > 0) {
			iconFill = '#AA0000';
		}
		let text =
			props?.mainFileText ||
			t('Drag and drop files here', { context: 'File dropzone' });
		if (rejectedFileNames.length > 0) {
			const rejectedFilesString = rejectedFileNames.join(', ');
			text = t(
				'File(s) "{{rejectedFilesString}}" could not be uploaded due to detected malware',
				{ rejectedFilesString, context: 'File dropzone' },
			);
		} else if (isDragReject) {
			text = t(
				// eslint-disable-next-line quotes
				"The files you're trying to import are in an invalid format",
				{ context: 'File dropzone' },
			);
		}
		const maxSize = (props?.dropzoneOptions?.maxSize || 0) / (1024 * 1024);
		if (uploadedFiles.length) {
			return (
				<DropzoneInfoContainerMini
					className={getDragClassName(
						isDragActive,
						isDragAccept,
						isDragReject,
					)}>
					<DropzoneInfoContainerLabelMini>
						<UploadFilePaper fill={iconFill} />
						<div>{text}</div>
					</DropzoneInfoContainerLabelMini>
				</DropzoneInfoContainerMini>
			);
		} else {
			let icon = <UpArrowCircle fill={iconFill} />;
			if (rejectedFileNames.length > 0) {
				icon = <RejectedFile />;
			}
			return (
				<DropzoneInfoContainerFull
					className={getDragClassName(
						isDragActive,
						isDragAccept,
						isDragReject,
					)}>
					<StyledDropzoneFileRowContainer>
						{icon}
						<h3>{text}</h3>
						<p>
							{props?.supportedFileText ||
								t(
									'Supported file types include: .doc, .xls, .png, .mp3, .mp4',
									{ context: 'File dropzone' },
								)}
						</p>
						{props?.dropzoneOptions?.maxSize ? (
							<p>
								{t('Max size {{maxSize}} MB', {
									maxSize,
									context: 'File dropzone',
								})}
							</p>
						) : null}
					</StyledDropzoneFileRowContainer>
				</DropzoneInfoContainerFull>
			);
		}
	};
	return (
		<Container>
			<StylovyzeDropzoneRowContainer
				files={uploadedFiles}
				onDownloadClick={handleDownloadClick}
				onDeleteClick={handleDeleteClick}
			/>
			{props.noUpload ? null : (
				<Dropzone
					{...props}
					accept={props?.dropzoneOptions?.accept}
					multiple={!props.single}
					onDropAccepted={onDropAccepted}>
					{({
						getRootProps,
						getInputProps,
						isDragActive,
						isDragAccept,
						isDragReject,
					}) => (
						<FittedLoader>
							<Loading loading={uploading}>
								<StyledDropzoneArea
									{...getRootProps({
										className: 'dropzone',
									})}>
									<input {...getInputProps()} />
									{getDZBody(
										isDragActive,
										isDragAccept,
										isDragReject,
									)}
								</StyledDropzoneArea>
							</Loading>
						</FittedLoader>
					)}
				</Dropzone>
			)}
		</Container>
	);
};

StylovyzeDropzone.defaultProps = {};

export default StylovyzeDropzone;
