import { Button } from '@mui/material';
import { useGlobalization } from '../../../contexts';

import { StyledFooter } from './Drawer.styles';
import React from 'react';
export interface DrawerFooterProps {
	action?: {
		title: string;
		onClick: () => void;
		enabled: boolean;
	};
	onCancelClick?: () => void;
	cancelButtonDisabled: boolean;
}

export const DrawerFooter = ({
	onCancelClick,
	action,
	cancelButtonDisabled,
}: DrawerFooterProps) => {
	const { t } = useGlobalization();
	return (
		<StyledFooter>
			{onCancelClick && (
				<Button
					variant="contained"
					color="secondary"
					onClick={onCancelClick}
					disabled={cancelButtonDisabled}
					data-cy="cancelButton">
					{t('Cancel')}
				</Button>
			)}
			{action && (
				<Button
					data-cy="createButton"
					type={'button'}
					variant="contained"
					color="primary"
					onClick={action.onClick}
					disabled={!action.enabled}>
					{action.title}
				</Button>
			)}
		</StyledFooter>
	);
};

export default DrawerFooter;
