import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as assetSchemaActions from '../actions/asset.actions';
import {
	getAllAssetsSchema,
	getAssetSchema,
	getAssetSchemaBySystemType,
} from '../services/asset.service';
import { SagaIterator } from 'redux-saga';
import {
	assetSchemaSelector,
	assetSchemaSelectorBySystemType,
	assetSchemaSelectorByType,
} from '../selectors';

export function* getAssetSchemaSaga(
	action: ReturnType<typeof assetSchemaActions.getAssetSchemaAction>,
): SagaIterator {
	const { systemType, assetType } = action.payload;
	try {
		const existingAssetSchema = yield select(
			assetSchemaSelectorByType,
			assetType,
		);
		if (existingAssetSchema) {
			return;
		}

		const assetSchema = yield call(getAssetSchema, {
			systemType,
			assetType,
		});

		if (assetSchema) {
			yield put(assetSchemaActions.getAssetSchemaResolved(assetSchema));
		} else {
			console.error(
				`Asset Type not found ${assetType} (systemType: ${systemType})`,
			);
			yield put(assetSchemaActions.getAssetSchemaRejected());
		}
	} catch (error) {
		console.error(
			`Asset Type not found ${assetType} (systemType: ${systemType})`,
			{ error },
		);
		yield put(assetSchemaActions.getAssetSchemaRejected());
	}
}

export function* assetSchemaSaga() {
	yield takeEvery(
		assetSchemaActions.getAssetSchemaAction,
		getAssetSchemaSaga,
	);
}

export function* getAssetSchemaBySystemTypeSaga(
	action: ReturnType<
		typeof assetSchemaActions.getAssetSchemaBySystemTypeAction
	>,
): SagaIterator {
	const systemType = action.payload;
	try {
		const existingAssetSchema = yield select(
			assetSchemaSelectorBySystemType,
			systemType,
		);
		if (
			existingAssetSchema != undefined &&
			existingAssetSchema.length > 0
		) {
			return;
		}

		const assetSchema = yield call(getAssetSchemaBySystemType, systemType);

		if (assetSchema) {
			yield put(
				assetSchemaActions.getAssetSchemaBySystemTypeResolved(
					assetSchema,
				),
			);
		} else {
			console.error(`Asset Types for ${systemType} not found`);
			yield put(assetSchemaActions.getAssetSchemaBySystemTypeRejected());
		}
	} catch (error) {
		console.error(`Asset Types for ${systemType} not found`, { error });
		yield put(assetSchemaActions.getAssetSchemaBySystemTypeRejected());
	}
}

export function* assetSchemaBySystemTypeSaga() {
	yield takeEvery(
		assetSchemaActions.getAssetSchemaBySystemTypeAction,
		getAssetSchemaBySystemTypeSaga,
	);
}

export function* getAllAssetsSchemaSaga(): SagaIterator {
	try {
		const existingAssetSchemas = yield select(assetSchemaSelector);
		if (Object.keys(existingAssetSchemas).length > 0) {
			return;
		}

		const assetSchemas = yield call(getAllAssetsSchema);
		if (assetSchemas.length > 0) {
			yield put(
				assetSchemaActions.getAllAssetsSchemaResolved(assetSchemas),
			);
		} else {
			console.error('No Asset Types found');
			yield put(assetSchemaActions.getAllAssetsSchemaRejected());
		}
	} catch (error) {
		console.error('No Asset Types found', { error });
		yield put(assetSchemaActions.getAllAssetsSchemaRejected());
	}
}

export function* allAssetsSchemaSaga() {
	yield takeLatest(
		assetSchemaActions.getAllAssetsSchemaAction,
		getAllAssetsSchemaSaga,
	);
}
