import {
	DataGrid,
	DataGridButton,
	DataGridButtonType,
	DataGridProps,
	useIsFeatureEnabled,
	success,
	error,
} from '@innovyze/stylovyze';
import {
	GridLogicOperator,
	GridRowId,
	useGridApiRef,
} from '@mui/x-data-grid-premium';
import { SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { SystemAssetSelectors, Wrapper } from './AssetGrid.styles';
import { generateColumns, generateSpatialColumns } from './columnMapping';
import { getGridData, getGridDataReset } from '@Actions/asset';
import {
	selectGridLoading,
	selectGridPagination,
	selectGridRows,
	selectGridSort,
	selectSelectedAssets,
} from '@Selectors/asset';
import {
	selectAssetGridColumnLayout,
	selectSpatialDataColumnLayout,
} from '@Selectors/map';
import { useDispatch, useSelector } from 'react-redux';

import { Asset, Filter } from '@Utils/types';
import { AssetLinkProps } from './AssetLink';
import { AssetTypeSelect } from './AssetTypeSelect';
import { SelectSystemAsset } from './SelectSystemAsset';
import { ShowInMenu } from './getActions';
import { SystemTypeSelect } from './SystemTypeSelect';
import { initialState as assetInitialState } from '@Reducers/asset';
import { convertSortModel } from '@Utils/grid';
import { getSpatialMetadata } from '@Actions/risk';
import { useCustomSelection } from '@Hooks/useCustomSelection';
import { useFilter } from '../../context';
import { useFilterModel } from './useFilterModel';
import { useGlobalization } from '@Translations/useGlobalization';
import { useGridViewManager } from './useGridViewManager';
import { useShallowEqlEffect } from '@Hooks/useShallowEqlEffect';
import { FilterSaveDialog } from '@Components/FilterSaveDialog';
import { FilterDrawer } from '@Components/FilterDrawer';
import { getAllAssetsSchemaAction } from '@innovyze/lib_asset_schema_store';
import * as FilterService from '@Services/filter';

export interface AssetGridProps {
	onViewDetails?: AssetLinkProps['onViewDetails'];
	onSelectedAssetsChange?: (selectedAssets: Asset[]) => void;
	actionMenuItems?: ShowInMenu;
}

export const AssetGrid = ({
	onViewDetails,
	onSelectedAssetsChange,
	actionMenuItems = [],
}: AssetGridProps): JSX.Element => {
	const dispatch = useDispatch();

	const { t } = useGlobalization();
	const isSpatialFilterEnabled = Boolean(
		useIsFeatureEnabled(
			'i-360-release-146545-add-filter-to-spatial-data-in-data-grid',
		),
	);

	const gridSelectFeature = Boolean(
		useIsFeatureEnabled('i-360-release-145673-data-grid-select'),
	);

	const saveFilterFeature = !!useIsFeatureEnabled(
		'info-360-assets-save-filter',
	);

	const [
		isViewSelected,
		{ dataType, layerName },
		setDataType,
		setLayerName,
	] = useGridViewManager();

	const isSpatialLayerSelected = dataType === 'SpatialData';

	const loading = useSelector(selectGridLoading);
	const rows = useSelector(selectGridRows);
	const pagination = useSelector(selectGridPagination);
	const sort = useSelector(selectGridSort);
	const filterButton = useRef(null);
	const apiRef = useGridApiRef();
	const {
		setAdditionalFilterProps,
		excludedFilter,
		filterCount,
	} = useFilter();

	const { columns, visibility } = useSelector(state => {
		if (isSpatialLayerSelected) {
			return selectSpatialDataColumnLayout(state, layerName);
		}

		return selectAssetGridColumnLayout(state, layerName);
	});

	const [paginationModel, setPaginationModel] = useState({
		page: pagination.current - 1,
		pageSize: pagination.limit,
	});
	const [sortModel, setSortModel] = useState(sort);
	const gridSortModel = useMemo(() => convertSortModel(sortModel), [
		sortModel,
	]);
	const [
		filterModel,
		setFilterModel,
		filterModelForServer,
		setFilterModelFromServer,
	] = useFilterModel(columns ?? []);
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		visibility,
	);

	const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
	const selectedAssets = useSelector(state =>
		selectSelectedAssets(state, selectionModel, { dataType, layerName }),
	);
	const [savedFilters, setSavedFilters] = useState<Filter[]>([]);

	const customSelection = useCustomSelection(paginationModel.pageSize);

	const onSortModelChange: DataGridProps['onSortModelChange'] = useCallback(
		model => {
			customSelection.resetSelection();
			if (model[0]?.field && model[0]?.sort) {
				setSortModel({
					sort: model[0]?.field,
					sortDescending: model[0]?.sort === 'desc',
				});
			} else {
				const { sort, sortDescending } = assetInitialState.assetGrid;
				setSortModel({
					sort,
					sortDescending,
				});
			}
		},
		[],
	);

	const onPageModelChange: DataGridProps['onPaginationModelChange'] = useCallback(
		model => {
			customSelection.resetSelection();
			setPaginationModel(oldModel => ({
				...oldModel,
				...model,
				// reset pagination to 1st page if the pagesize has changed
				page: oldModel.pageSize !== model.pageSize ? 0 : model.page,
			}));
		},
		[],
	);

	const onColumnVisibilityChange: DataGridProps['onColumnVisibilityModelChange'] = useCallback(
		model => {
			setColumnVisibilityModel(model);
		},
		[],
	);

	useEffect(() => {
		setColumnVisibilityModel(visibility);
	}, [visibility]);

	const resetFilters = () => {
		const model = { ...filterModel, items: [] };
		setFilterModel(model);
	};

	const onSystemTypeChange = (event: SelectChangeEvent<string>) => {
		const newSystemType = event.target.value;
		setDataType(newSystemType);
		resetFilters();
	};

	useEffect(() => {
		if (dataType) {
			setAdditionalFilterProps({ systemType: dataType });
		}
	}, [dataType]);

	const onAssetTypeChange = (event: SelectChangeEvent<string>) => {
		const newAssetType = event.target.value;
		setLayerName(newAssetType);
		setPaginationModel(model => ({
			...model,
			page: 0,
		}));
		resetFilters();
		setAdditionalFilterProps(state => ({
			...state,
			assetType: newAssetType,
		}));
	};

	const showFilterPanel = () => {
		apiRef.current.showFilterPanel();
	};

	const fetchData = () => {
		if (isViewSelected) {
			dispatch(
				getGridData({
					assetType: layerName,
					systemType: dataType,
					limit: paginationModel.pageSize,
					offset: paginationModel.page + 1,
					sort: sortModel.sort,
					sortDescending: sortModel.sortDescending,
					filterModel: filterModelForServer,
					exclude: excludedFilter,
				}),
			);
		} else {
			dispatch(getGridDataReset());
		}
	};

	useEffect(() => {
		fetchData();
	}, [
		paginationModel.page,
		paginationModel.pageSize,
		dataType,
		layerName,
		sortModel.sort,
		sortModel.sortDescending,
		JSON.stringify(filterModelForServer),
		JSON.stringify(excludedFilter),
	]);

	const saveFilter = async (name: string) => {
		try {
			// eslint-disable-next-line quotes
			const now = DateTime.now().toFormat("ccc d LLL 'at' HH:mm");
			const payload: Filter = {
				name: name || `Filter saved on ${now}`,
				type: filterModel.logicOperator ?? GridLogicOperator.And,
				assetType: layerName,
				systemType: dataType,
				filters: filterModelForServer.items.map(f => ({
					field: f.field,
					operator: f.operator,
					value: f.value,
				})),
			};
			const res = await FilterService.saveFilter(payload);
			if (res.data?._id) {
				setSavedFilters([res.data, ...savedFilters]);
				dispatch(success('Filter saved successfully'));
			} else {
				throw new Error();
			}
		} catch (e) {
			dispatch(error('Error saving filter'));
		}
	};

	const applyFilter = async (filter: Filter) => {
		const model = {
			...filterModel,
			logicOperator: filter.type,
			items: filter.filters,
		};
		setFilterModelFromServer(model);
		await new Promise(resolve => setTimeout(resolve, 500));
		dispatch(success('Filter model applied'));
	};

	useEffect(() => {
		if (saveFilterFeature) {
			FilterService.loadFilters({ assetType: layerName })
				.then(res => setSavedFilters(res.data))
				.catch(() => dispatch(error('Error getting saved filters')));
		}
	}, [layerName]);

	useShallowEqlEffect(() => {
		onSelectedAssetsChange?.(selectedAssets);
	}, [selectedAssets]);

	useEffect(() => {
		dispatch(getAllAssetsSchemaAction());
		dispatch(getSpatialMetadata());
	}, []);

	return (
		<Wrapper>
			<SystemAssetSelectors>
				<SystemTypeSelect
					systemType={dataType}
					onSystemTypeChange={onSystemTypeChange}
					dataCy="system-type-select-top-bar"
					topBar
				/>
				<AssetTypeSelect
					systemType={dataType}
					assetType={layerName}
					onAssetTypeChange={onAssetTypeChange}
					dataCy="asset-type-select-top-bar"
					topBar
				/>
				{saveFilterFeature && (
					<FilterSaveDialog onSave={saveFilter}>
						<DataGridButton
							type={DataGridButtonType.SAVE}
							disabled={!filterCount}
							data-cy="saveButton"
						/>
					</FilterSaveDialog>
				)}
				{saveFilterFeature && (
					<FilterDrawer
						onSelect={applyFilter}
						assetType={layerName}
						filters={savedFilters}>
						<DataGridButton
							type={DataGridButtonType.SAVED_FILTERS}
							disabled={
								!isViewSelected ||
								(isSpatialLayerSelected &&
									!isSpatialFilterEnabled)
							}
							data-cy="savedFilters"
						/>
					</FilterDrawer>
				)}
				<DataGridButton
					type={DataGridButtonType.FILTER}
					disabled={
						!isViewSelected ||
						(isSpatialLayerSelected && !isSpatialFilterEnabled)
					}
					data-cy="filterButton"
					onClick={showFilterPanel}
					ref={filterButton}
					style={{
						marginRight: '0.5rem',
					}}
				/>
				<DataGridButton
					type={DataGridButtonType.REFRESH}
					disabled={!isViewSelected}
					data-cy="refreshButton"
					onClick={fetchData}
				/>
			</SystemAssetSelectors>
			{!isViewSelected && (
				<SelectSystemAsset
					assetType={layerName}
					systemType={dataType}
					onSystemTypeChange={onSystemTypeChange}
					onAssetTypeChange={onAssetTypeChange}
				/>
			)}
			{isViewSelected && (
				<DataGrid
					apiRef={apiRef}
					columns={
						isSpatialLayerSelected
							? generateSpatialColumns(
									columns,
									t,
									gridSelectFeature,
									customSelection,
									isSpatialFilterEnabled
										? actionMenuItems
										: [],
							  )
							: generateColumns(
									columns,
									layerName,
									dataType,
									t,
									onViewDetails,
									actionMenuItems,
									gridSelectFeature,
									customSelection,
							  )
					}
					loading={!rows?.length && loading}
					rows={rows ?? []}
					getRowId={row => row._id}
					height="calc(100% - 75px)"
					autoHeight={false}
					pageSizeOptions={[5, 10, 15, 25, 50, 100]}
					pagination
					paginationMode="server"
					paginationModel={paginationModel}
					onPaginationModelChange={onPageModelChange}
					rowCount={pagination.total}
					onRowSelectionModelChange={setSelectionModel}
					footerHelper={t('{{from}}-{{to}} of {{total}}', {
						from: (pagination.current - 1) * pagination.limit + 1,
						to:
							pagination.current == pagination.pages
								? pagination.total
								: pagination.current * pagination.limit,
						total: pagination.total,
					})}
					sortingMode="server"
					sortModel={gridSortModel}
					onSortModelChange={onSortModelChange}
					filterMode="server"
					filterModel={filterModel}
					onFilterModelChange={setFilterModel}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={onColumnVisibilityChange}
					slotProps={{
						panel: {
							anchorEl: filterButton.current,
							placement: 'left',
						},
					}}
					data-cy="asset-grid"
				/>
			)}
		</Wrapper>
	);
};
