(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@reduxjs/toolkit"), require("@innovyze/inno-map"), require("@innovyze/shared-utils"), require("@innovyze/stylovyze"), require("luxon"), require("@innovyze/lib_asset_schema"), require("@innovyze/lib_get_service"), require("@innovyze/shared-utils/lib/geometry/geojson"), require("react"), require("@redux-saga/core/effects"), require("@innovyze/map-components"), require("lodash"), require("redux"));
	else if(typeof define === 'function' && define.amd)
		define(["@Reduxjs/Toolkit", "@Innovyze/InnoMap", "@innovyze/shared-utils", "@Innovyze/Stylovyze", "Luxon", "@innovyze/lib_asset_schema", "@innovyze/lib_get_service", "@innovyze/shared-utils/lib/geometry/geojson", "React", "@redux-saga/core/effects", "@Innovyze/MapComponents", "Lodash", "Redux"], factory);
	else if(typeof exports === 'object')
		exports["lib-am-common"] = factory(require("@reduxjs/toolkit"), require("@innovyze/inno-map"), require("@innovyze/shared-utils"), require("@innovyze/stylovyze"), require("luxon"), require("@innovyze/lib_asset_schema"), require("@innovyze/lib_get_service"), require("@innovyze/shared-utils/lib/geometry/geojson"), require("react"), require("@redux-saga/core/effects"), require("@innovyze/map-components"), require("lodash"), require("redux"));
	else
		root["lib-am-common"] = factory(root["@Reduxjs/Toolkit"], root["@Innovyze/InnoMap"], root["@innovyze/shared-utils"], root["@Innovyze/Stylovyze"], root["Luxon"], root["@innovyze/lib_asset_schema"], root["@innovyze/lib_get_service"], root["@innovyze/shared-utils/lib/geometry/geojson"], root["React"], root["@redux-saga/core/effects"], root["@Innovyze/MapComponents"], root["Lodash"], root["Redux"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__reduxjs_toolkit__, __WEBPACK_EXTERNAL_MODULE__innovyze_inno_map__, __WEBPACK_EXTERNAL_MODULE__innovyze_shared_utils__, __WEBPACK_EXTERNAL_MODULE__innovyze_stylovyze__, __WEBPACK_EXTERNAL_MODULE_luxon__, __WEBPACK_EXTERNAL_MODULE__innovyze_lib_asset_schema__, __WEBPACK_EXTERNAL_MODULE__innovyze_lib_get_service__, __WEBPACK_EXTERNAL_MODULE__innovyze_shared_utils_lib_geometry_geojson__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE__redux_saga_core_effects__, __WEBPACK_EXTERNAL_MODULE__innovyze_map_components__, __WEBPACK_EXTERNAL_MODULE_lodash__, __WEBPACK_EXTERNAL_MODULE_redux__) => {
return 