import { Field, FieldType } from '@Types/map.types';
import {
	excludedFilterColumn,
	spatialFilterColumn,
} from '@Components/AssetGrid/columnMapping';

import { createSelector } from '@reduxjs/toolkit';
import { isValidFieldType } from '@Utils/helpers';
import { selectSpatialLayerMeta } from './risk';
import { assetSchemaSelectorByType } from '@innovyze/lib_asset_schema_store';
import { SingleProperty } from '@innovyze/lib_asset_schema';
import { getFieldTypeMapping } from './getFieldTypeMapping';

const DEFAULT_COLUMN_WIDTH = 150;

const isVisibleByDefault = (property: SingleProperty): boolean =>
	property.hasCapability('ShowOnMapPanel');

const mapAssetPropertyToField = (property: SingleProperty): Field => {
	const { fieldType, precision, multiline } = getFieldTypeMapping(property);
	return {
		name: property.displayName,
		fieldKey: property.id,
		fieldType,
		columnWidth: DEFAULT_COLUMN_WIDTH,
		precision,
		units: property.units,
		multiline,
		visible: isVisibleByDefault(property),
	};
};

const calculateVisibility = (columns: Pick<Field, 'fieldKey' | 'visible'>[]) =>
	columns.reduce<Record<string, boolean>>((visibility, column) => {
		visibility[column.fieldKey] = column.visible ?? false;
		return visibility;
	}, {});

export const selectAssetGridColumnLayout = createSelector(
	[assetSchemaSelectorByType],
	assetSchema => {
		const properties = assetSchema?.getProperties() ?? [];

		const propertyColumns: Field[] = properties
			.flatMap(property =>
				property.type === 'List' ? property.properties : [property],
			)
			.map(mapAssetPropertyToField);

		const columns = [
			...propertyColumns,
			spatialFilterColumn,
			excludedFilterColumn,
		];

		return { columns, visibility: calculateVisibility(columns) };
	},
);

export const selectSpatialDataColumnLayout = createSelector(
	[selectSpatialLayerMeta],
	layerMeta => {
		const propertyColumns = layerMeta.map(({ property, type }) => ({
			fieldKey: property,
			fieldType: correctFieldType(type),
			name: property,
			visible: true,
			columnWidth: DEFAULT_COLUMN_WIDTH,
		}));

		const columns = [
			...propertyColumns,
			spatialFilterColumn,
			excludedFilterColumn,
		];

		return { columns, visibility: calculateVisibility(columns) };
	},
);

const correctFieldType = (fieldType: string): FieldType => {
	if (fieldType === 'datetime' || fieldType === 'time') {
		return FieldType.Date;
	}
	if (isValidFieldType(fieldType)) {
		return fieldType as FieldType;
	}
	return FieldType.String;
};
