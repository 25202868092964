import { FormControlOutline, FormControlUnderline } from './Select.styles';
import { InputLabel, MenuItem, Select } from '@mui/material';
import React, { ElementType, useEffect } from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { selectLayersNamesInDataType } from '@Selectors/asset';
import { useGlobalization } from '@Translations/useGlobalization';
import { useDispatch, useSelector } from 'react-redux';
import {
	assetSchemaSelector,
	getAssetSchemaAction,
} from '@innovyze/lib_asset_schema_store';
import { camelAndSnakeCaseToWords } from '@Utils/helpers';

export interface AssetTypeSelectProps {
	systemType: string;
	assetType: string;
	onAssetTypeChange: SelectInputProps<string>['onChange'];
	topBar?: boolean;
	dataCy?: string;
}

export const AssetTypeSelect = ({
	systemType,
	assetType,
	onAssetTypeChange,
	topBar,
	dataCy,
}: AssetTypeSelectProps): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();

	const assetTypes = useSelector(state =>
		selectLayersNamesInDataType(state, systemType),
	);

	useEffect(() => {
		assetTypes?.forEach(assetType =>
			dispatch(getAssetSchemaAction({ assetType })),
		);
	}, [assetTypes, dispatch]);

	const assetSchema = useSelector(state => assetSchemaSelector(state));

	const FormControl: ElementType = topBar
		? FormControlUnderline
		: FormControlOutline;

	return (
		<FormControl>
			<InputLabel shrink={topBar}>{t('Layer Name')}</InputLabel>
			<Select
				displayEmpty
				value={assetType}
				disabled={!systemType}
				onChange={onAssetTypeChange}
				data-cy={dataCy}>
				{topBar && (
					<MenuItem disabled value="">
						{t('Select a layer')}
					</MenuItem>
				)}
				{assetTypes?.map(assetType => (
					<MenuItem key={assetType} value={assetType}>
						{assetSchema[assetType]?.displayName ??
							camelAndSnakeCaseToWords(assetType)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
