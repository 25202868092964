/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

import config from '../../apis/config.api';
import { Export, ExportModel } from '../../types/export.types';
import { getService, getTenantId } from '@innovyze/lib_get_service';
import { getApiEnvironment } from '../../utils/getApiEnvironment';
import { getBearerAuth0Token } from '../../utils/authUtils';

// use local definition in stylovyze once ExportDialog is moved into that library
const exportBaseURL = getService('export', config, getApiEnvironment());

export const constructRequestBody = (
	source: string,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	params: any,
): { source: string; requestParams: Record<string, string> } => {
	return {
		source,
		requestParams: params,
	};
};

const getHeaders = () => {
	const headers: Record<string, string> = {
		Authorization: getBearerAuth0Token(),
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};
	const tenantId = getTenantId();
	if (tenantId) headers['x-tenant-id'] = tenantId;

	return headers;
};

export const postExport = async (
	source: string,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	params: any,
): Promise<Export | undefined> => {
	const requestBody = constructRequestBody(source, params);

	try {
		const response = await fetch(exportBaseURL + '/export', {
			method: 'POST',
			headers: getHeaders(),
			body: JSON.stringify(requestBody),
		});
		if (!response.ok) {
			throw new Error('unable to initialize export');
		}
		return await response.json();
	} catch (e) {
		console.error('error: ', e);
		return Promise.resolve(undefined);
	}
};

export const getExportStatus = async (
	exportId: string,
): Promise<ExportModel | undefined> => {
	try {
		const response = await fetch(
			`${exportBaseURL}/export/${encodeURIComponent(exportId)}`,
			{
				method: 'GET',
				headers: getHeaders(),
			},
		);
		if (!response.ok) {
			throw new Error(
				'getExportStatus did not succeed for exportID: ' + exportId,
			);
		}
		return await response.json();
	} catch (e) {
		console.error('error: ', e);
		return;
	}
};

export const pollExportStatus = async (
	exportId: string,
	setExportResult: Dispatch<SetStateAction<ExportModel>>,
): Promise<ExportModel | void> => {
	const exportStatus = await getExportStatus(exportId);
	if (exportStatus?.status) {
		setExportResult(exportStatus);
	}
	if (exportStatus?.status === 'success' && exportStatus?.downloadUrl) {
		return exportStatus;
	} else if (exportStatus?.status === 'error') {
		return exportStatus;
	}
};
