import { AxiosResponse } from 'axios';
import service from '@Apis/asset.management.api';
import { Filter } from '@Utils/types';

export type SaveFilterResponse = Filter & Record<string, any>;

export const saveFilter = (
	filter: Filter,
): Promise<AxiosResponse<SaveFilterResponse>> => {
	return service.post('/assets-filter', filter);
};

export const loadFilters = (
	query?: Record<string, string>,
): Promise<AxiosResponse<Filter[]>> => {
	return service.get(
		`/assets-filter/?${new URLSearchParams(query).toString()}`,
	);
};
