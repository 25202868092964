export type Feature = boolean | number | string;

export type Features = {
	[k: string]: string;
};

export type FeatureFlags = keyof Features;

export enum STYLOVYZE_FLAGS {
	TEST = 'info-360-test',
	ANALYTICS_REFERENCE_CHARTS = 'info-360-analytics-reference-charts',
	ADMIN_BILLING = 'info-360-admin-billing',
	INSIGHT_ABOUT_BOX = 'info360-insight-about-box',
	ASSET_ABOUT_BOX = 'info360-asset-about-box',
	LOCALE = 'info-360-locale',
	WATER_DISTRIBUTION_ASSET_UPLOAD = 'info-360-water-distribution-asset',
	WATER_DISTRIBUTION_ASSET_SYSTEM_TYPE_SELECT = 'info-360-water-distribution-asset-system-type-select',
	REHAB_MULTIPLE_RISK_MODELS = 'info-360-asset-multiple-risk-models-rehab',
	ABOUT_TO_EXPIRE = 'info-360-about-to-expire',
	DEBUG_MODE = 'info-360-debug-messages',
	REHAB_CUSTOM_TABLES_RECORD_TYPE = 'info-360-rehab-custom-tables-record-type',
	AM_MAP = 'info-360-am-map',
	AM_INSPECTIONS = 'info-360-am-inspections',
	AM_RISK = 'info-360-am-risk',
	AM_GIS_IMPORT = 'info-360-am-gis-import',
	AM_REHAB = 'info-360-asset-rehab',
	AM_ADMIN = 'info-360-am-admin',
	ANALYTIC_HISTORIC_FORECAST = 'info-360-analytic-historic-forecast',
	ADVANCED_SUPPORT = 'info-360-advanced-support-features',
	USER_READONLY_ADMIN = 'info-360-admin-read-only-for-users',
	ARCGIS_BASEMAPS = 'info-360-arcgis-basemaps',
	SESSION_SHARED_WORKERS = 'info-360-session-sharedworkers',
	ADSK_ADP_ANALYTICS = 'info-360-adp-analytics',
	ADSK_ADP_ANALYTICS_DEBUGGING = 'info-360-adp-analytics-debugging',
	REGENERATE_SHARED_WORKER = 'info-360-regenerate-shared-worker',
}

export type AdvancedSupportFeature = {
	[STYLOVYZE_FLAGS.ADVANCED_SUPPORT]: Feature;
};
