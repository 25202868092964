import * as React from 'react';

import { AttachedFile, UploadedFile } from '../../types/files';
import {
	Container,
	DropzoneInfoContainerFull,
	DropzoneInfoContainerLabelMini,
	DropzoneInfoContainerMini,
	FittedLoader,
	StyledDropzoneArea,
	StyledDropzoneFileRowContainer,
} from './FileDropzone.styles';
import Dropzone, { DropEvent, DropzoneOptions } from 'react-dropzone';

import DropzoneFileRowContainer from './DropzoneFileRowContainer';
import Loading from '../../components/Loading';
import S3Utils from '../../utils/s3utils';
import { UpArrowCircle } from './icons';
import UploadFilePaper from './icons/UploadFilePaper';
import { useGlobalization } from '../../contexts/GlobalizationProvider';
import { useState } from 'react';

export interface FileDropzoneProps {
	dropzoneOptions?: DropzoneOptions;
	signApiUrl?: string;
	downloadApiUrl?: string;
	deleteApiUrl?: string;
	onFileDelete?: (af: AttachedFile) => void;
	params?: { [key: string]: string };
	noUpload?: boolean;
	token: string;
	files?: AttachedFile[];
	supportedFileText?: string;
	onDropAcceptedExternal?: <T extends File>(
		files: T[],
		event: DropEvent,
	) => void;
	showDeleteButton?: boolean;
	hideFileDate?: boolean;
	hideFileExtension?: boolean;
	injectTenantId?: boolean;
}

/**
 * Add a description for your component here
 */
export const FileDropzone = (props: FileDropzoneProps): JSX.Element => {
	const { t } = useGlobalization();
	const {
		downloadApiUrl,
		deleteApiUrl,
		token,
		injectTenantId = true,
	} = props;
	const [files, setFiles] = useState<AttachedFile[]>(props.files ?? []);
	const [uploading, setUploading] = useState<boolean>(false);

	React.useEffect(() => {
		if (props?.files && props.files.length > 0) {
			setFiles(props.files);
		}
	}, [props.files]);
	let handleDownloadClick:
		| ((af: AttachedFile) => () => Promise<void>)
		| undefined = undefined;
	if (downloadApiUrl) {
		handleDownloadClick = (af: AttachedFile) => async (): Promise<void> => {
			const utils: S3Utils = new S3Utils();
			const { path, dateCreated } = af;
			const fileProperties = {
				...af?.props,
				...props.params,
			};
			utils
				.downloadFromS3(
					downloadApiUrl ?? '',
					{
						path,
						dateCreated,
						...fileProperties,
					},
					token,
				)
				.then(resp => {
					if (resp?.location) {
						const href: HTMLAnchorElement =
							document.createElement('a');
						href.setAttribute('href', resp.location);
						href.setAttribute('download', 'download');
						href.click();

						href.remove();
					}
				});
		};
	}

	let handleDeleteClick:
		| ((af: AttachedFile, index: number) => () => Promise<void>)
		| undefined = undefined;
	if (deleteApiUrl) {
		handleDeleteClick =
			(af: AttachedFile, index: number) => async (): Promise<void> => {
				const utils: S3Utils = new S3Utils();
				const { path, dateCreated } = af;
				const fileProperties = {
					...af?.props,
					...props.params,
				};
				utils
					.delete(
						deleteApiUrl,
						{
							path,
							dateCreated,
							...fileProperties,
						},
						token,
					)
					.then(() => {
						setFiles(files.toSpliced(index, 1));
						if (props.onFileDelete) props.onFileDelete(af);
					});
			};
	} else if (props.showDeleteButton || props.onFileDelete) {
		handleDeleteClick =
			(af: AttachedFile, index: number) => async (): Promise<void> => {
				setFiles(files.toSpliced(index, 1));
				if (props.onFileDelete) props.onFileDelete?.(af);
			};
	}

	const onDropAccepted = (acceptedFiles: File[], evt: DropEvent) => {
		const acceptedUploadedFiles: UploadedFile[] = [];
		if (props.signApiUrl) {
			const uploader: S3Utils = new S3Utils();

			setUploading(true);
			uploader
				.uploadToS3(
					props?.signApiUrl,
					acceptedFiles,
					props.params || {},
					props.token,
					injectTenantId,
				)
				.then(signedResponses => {
					const attachedFiles: AttachedFile[] = [];
					acceptedFiles.forEach(file => {
						const signedResponse = signedResponses?.find(
							el => file.name == el.name,
						);

						acceptedUploadedFiles.push({
							...file,
							dateCreated: signedResponse?.dateCreated || -1,
						});

						attachedFiles.push({
							path: file.name,
							dateCreated: signedResponse?.dateCreated || -1,
						});
					});

					setFiles([...files, ...attachedFiles]);
					if (props.dropzoneOptions?.onDropAccepted)
						props.dropzoneOptions.onDropAccepted<UploadedFile>(
							acceptedUploadedFiles,
							evt,
						);

					setUploading(false);
				})
				.catch(() => setUploading(false));
		} else {
			const attachedFiles: AttachedFile[] = [];
			acceptedFiles.forEach(file => {
				acceptedUploadedFiles.push({
					...file,
					dateCreated: file.lastModified,
				});

				attachedFiles.push({
					path: file.name,
					dateCreated: file.lastModified,
				});
			});
			setFiles([...files, ...attachedFiles]);
			if (props.dropzoneOptions?.onDropAccepted)
				props.dropzoneOptions.onDropAccepted<File>(acceptedFiles, evt);
		}
	};

	const getDragClassName = (
		isDragActive: boolean,
		isDragAccept: boolean,
		isDragReject: boolean,
	): string => {
		const classNames: string[] = [];
		if (isDragAccept) classNames.push('drag-accept');
		if (isDragActive) classNames.push('drag-active');
		if (isDragReject) classNames.push('drag-reject');
		return classNames.join(' ');
	};
	const getDZBody = (
		isDragActive: boolean,
		isDragAccept: boolean,
		isDragReject: boolean,
	) => {
		if (files.length) {
			return (
				<DropzoneInfoContainerMini
					className={getDragClassName(
						isDragActive,
						isDragAccept,
						isDragReject,
					)}>
					<DropzoneInfoContainerLabelMini>
						<UploadFilePaper
							fill={isDragReject ? '#AA0000' : '#4A6067'}
						/>
						<div>
							{isDragReject
								? t(
										// eslint-disable-next-line quotes
										"The files you're trying to import are in an invalid format",
										{ context: 'File dropzone' },
								  )
								: t('Drag and drop files here', {
										context: 'File dropzone',
								  })}
						</div>
					</DropzoneInfoContainerLabelMini>
				</DropzoneInfoContainerMini>
			);
		} else {
			const maxSize =
				(props?.dropzoneOptions?.maxSize || 0) / (1024 * 1024);
			return (
				<DropzoneInfoContainerFull
					data-testid="dropzone"
					className={getDragClassName(
						isDragActive,
						isDragAccept,
						isDragReject,
					)}>
					<StyledDropzoneFileRowContainer>
						<UpArrowCircle
							fill={isDragReject ? '#AA0000' : '#C9D6DC'}
						/>
						<h3>
							{isDragReject
								? t(
										// eslint-disable-next-line quotes
										"The files you're trying to import are in an invalid format",
										{ context: 'File dropzone' },
								  )
								: t('Drag and drop files here', {
										context: 'File dropzone',
								  })}
						</h3>
						<p>
							{props?.supportedFileText ||
								t(
									'Supported file types include: .doc, .xls, .png, .mp3, .mp4',
									{ context: 'File dropzone' },
								)}
						</p>
						{props?.dropzoneOptions?.maxSize ? (
							<p>
								{t('Max size {{maxSize}} MB', {
									maxSize,
									context: 'File dropzone',
								})}
							</p>
						) : null}
					</StyledDropzoneFileRowContainer>
				</DropzoneInfoContainerFull>
			);
		}
	};
	return (
		<Container>
			<DropzoneFileRowContainer
				files={files}
				hideFileDate={props?.hideFileDate}
				hideFileExtension={props?.hideFileExtension}
				params={props?.params}
				onDownloadClick={handleDownloadClick}
				onDeleteClick={handleDeleteClick}
				token={props.token}
			/>
			{props.noUpload ? null : (
				<Dropzone
					{...props}
					accept={props?.dropzoneOptions?.accept}
					maxSize={props?.dropzoneOptions?.maxSize}
					maxFiles={props?.dropzoneOptions?.maxFiles}
					validator={props?.dropzoneOptions?.validator}
					getFilesFromEvent={
						props?.dropzoneOptions?.getFilesFromEvent
					}
					multiple={true}
					onDrop={props?.dropzoneOptions?.onDrop}
					onDropAccepted={
						props?.onDropAcceptedExternal || onDropAccepted
					}>
					{({
						getRootProps,
						getInputProps,
						isDragActive,
						isDragAccept,
						isDragReject,
					}) => (
						<FittedLoader>
							<Loading loading={uploading}>
								<StyledDropzoneArea
									{...getRootProps({
										className: 'dropzone',
									})}>
									<input {...getInputProps()} />
									{getDZBody(
										isDragActive,
										isDragAccept,
										isDragReject,
									)}
								</StyledDropzoneArea>
							</Loading>
						</FittedLoader>
					)}
				</Dropzone>
			)}
		</Container>
	);
};

FileDropzone.defaultProps = {};

export default FileDropzone;
