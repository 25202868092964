import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { CurveManagerForm } from '../../../types';
import { DropzoneTable } from '../CreateOrEditCurveDialog/CreateOrEditCurveDialog.styles';
import {
	CurveManagerDrawerTable,
	CurveManagerFileDropzone,
} from '../CurveManagerDrawer';
import { useGlobalization } from '../../../contexts';
import { CURVE_TABLE_HEADERS } from '../../../utils/curveManagerUtils';
import { checkDuplicateCurveById } from '../../../actions';
import { useDispatch } from 'react-redux';
import { useSelectCurveManager } from '../../../selectors';
import { useIsFeatureEnabled } from '../../../utils';

interface Props {
	hasDepthArrayData: boolean;
	setFilesLoading: React.Dispatch<React.SetStateAction<boolean>>;
	filesLoading: boolean;
}

export const CurveDataDisplay = ({
	hasDepthArrayData,
	setFilesLoading,
	filesLoading,
}: Props) => {
	const { values } = useFormikContext<CurveManagerForm>();
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const {
		overwriteModal: { validatedAssets },
	} = useSelectCurveManager();

	const multipleCurveUploadEnabled = !!useIsFeatureEnabled(
		'info360-multiple-curve-upload',
	);

	useEffect(() => {
		if (values.curveData.length <= 1 || !multipleCurveUploadEnabled) {
			return;
		}
		dispatch(
			checkDuplicateCurveById({
				form: values,
				validatedAssets,
			}),
		);
	}, [values, multipleCurveUploadEnabled]);

	if (hasDepthArrayData && values.curveData?.length === 1) {
		return (
			<DropzoneTable>
				<CurveManagerDrawerTable
					dataCy={'curves-table'}
					headers={CURVE_TABLE_HEADERS(t)}
				/>
			</DropzoneTable>
		);
	}

	return (
		<CurveManagerFileDropzone
			setFilesLoading={setFilesLoading}
			filesLoading={filesLoading}
		/>
	);
};
