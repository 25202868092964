import type * as Highcharts from 'highcharts';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Gauge Chart
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const gaugeInstanceInitialOptions: Highcharts.Options = {
  chart: { type: 'gauge', height: null },
  credits: { enabled: false },
  tooltip: { enabled: false },
  exporting: { enabled: false },
  title: { text: '' },
  pane: {
    startAngle: -150,
    endAngle: 150,
    background: [
      {
        borderWidth: 0,
        borderColor: '#D2DEE3',
        backgroundColor: '#D2DEE3',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    ],
  },
  yAxis: {
    minorTickInterval: undefined,
    tickWidth: 0,
    tickLength: 0,
    endOnTick: true,
    startOnTick: true,
    labels: { distance: 25, style: { color: '#768D95' } },
  },
  plotOptions: {
    gauge: {
      wrap: false,
      dataLabels: { enabled: false },
      dial: {
        radius: '90%',
        baseWidth: 4,
        topWidth: 4,
      },
    },
  },
};

const lineInstanceInitialOptions: Highcharts.Options = {
  chart: { height: 140, zoomType: 'x' },
  title: { text: '' },
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: false },
  navigator: { enabled: false },
  rangeSelector: { enabled: false },
  scrollbar: { enabled: false },
  xAxis: { type: 'datetime', gridLineWidth: 1 },
  yAxis: {
    opposite: false,
    gridLineWidth: 1,
    title: { text: '' },
  },
  plotOptions: {
    series: { 
      dataGrouping: { enabled: false },
      events: {
        afterAnimate: function () {
          if(this.graph)
            this.graph?.attr({ class: 'gaugeChartHistoricalSeries' });
        }
      }
    },
  },
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export { gaugeInstanceInitialOptions, lineInstanceInitialOptions };
