import { AssetStoreState } from './asset.types';
import { RiskStoreState } from './risk.types';
import { moduleNamespace } from '../store.config';

import { InspectionStoreState } from './inspection.types';
import { FacilityStoreState } from './facility.types';
import { AssetSchemaRootState } from '@innovyze/lib_asset_schema_store';
// plop - add type import here

export interface StoreState {
	risk: RiskStoreState;
	asset: AssetStoreState;
	inspection: InspectionStoreState;
	facility: FacilityStoreState;
	// plop - add type reducer here
}

export interface NamespacedStoreState {
	[moduleNamespace]: StoreState;
}

declare module 'react-redux' {
	interface DefaultRootState
		extends StoreState,
			NamespacedStoreState,
			AssetSchemaRootState {
		// extend any other module state here
	}
}
